import { Message } from 'element-ui';

export const copyText = (text) => {
  if ('clipboard' in navigator && 'writeText' in navigator.clipboard) {
    // 浏览器支持 navigator.clipboard.writeText() 方法
    navigator.clipboard.writeText(text)
      .then(() => {
        Message.success('复制成功！');
      })
      .catch(() => {
        Message.error('复制失败！');
      });
  } else {
    // 浏览器不支持 navigator.clipboard.writeText() 方法
    // 需要提供备用方案或提示用户无法复制
    const inputDom = document.createElement('input');
    inputDom.value = text;
    document.body.appendChild(inputDom);
    inputDom.select();
    document.execCommand('copy');
    document.body.removeChild(inputDom);
    Message.success('复制成功！');
  }
};

export const delayed = (time = 1000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

/**
 * 防抖函数
 * @param {func} func - 第一个函数
 * @param {number} turation - 第二个数字
 * @returns {func} - 返回一个函数
 */
export const debounce = (fn, turation = 500) => {
  let time;
  return function (...ager) {
    clearTimeout(time);
    time = setTimeout(() => {
      fn.apply(this, ager);
    }, turation);
  };
};
